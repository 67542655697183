var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { colds: "12", md: "8" } },
                [
                  _vm.GF.a1ElectricityFromLocalNetwork
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-1", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              39,
                                              "A1 - Connection to local electricity network"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "A1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.a2GreenEnergy
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-2", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(4, "A2 - Green energy")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "A2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.a3LedLighting
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-3", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(5, "A3 - Led lighting")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "A3",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.b1VehiculeEuro5
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-4", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              6,
                                              "B1 - Vehicule Euro 5"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "B1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.b2VehiculeGreen
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-5", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              7,
                                              "B2 - Vehicule Green"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "B2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.b3AccomodationCloseBy
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-6", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              8,
                                              "B3 - Accomodation close by"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "B3",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.b4GreenLabelAccomodation
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-7", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              9,
                                              "B4 - Green label accomodation"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "B4",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c1TabWater
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-8", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(10, "C1 - Tab water")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C1-1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c1WaterFontain
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-9", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              11,
                                              "C1 - Water fontain"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C1-2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c2Catering
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-10", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(12, "C2 - Catering")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C2-1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c2Restaurant
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-11", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(13, "C2 - Restaurant")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C2-2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c3ReusableDishes
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-12", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              14,
                                              "C3 - Reusable dishes"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C3",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c4WashableCup
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-13", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              15,
                                              "C4 - Washable cup"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C4-1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.c4RecyclableCup
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-14", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              16,
                                              "C4 - Recyclable cup"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "C4-2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.d1MaterialSupplierFsc
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-15", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              17,
                                              "D1 - Material supplier FSC"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "D1-1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.d1MaterialSupplierEmas
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-16", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              18,
                                              "D1 - Material supplier Emas"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "D1-2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.d2MaterialRecycled
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-17", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              19,
                                              "D2 - Material recycled"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "D2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.d3ReusableStageMaterial
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-18", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              20,
                                              "D3 - Reusable stage material"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "D3",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.d4ElectronicInfoExchange
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-19", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              21,
                                              "D4 - Electronical info exchange"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "D4",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.e1SortWaste
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-20", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(22, "E1 - Sort waste")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "E1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.f1ComPressFile
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-21", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              23,
                                              "F1 - Communication press file"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "F1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.f2ComClip
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-22", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              24,
                                              "F2 - Communication clip"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "F2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.GF.f3ComFromProduction
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { id: "form-step-23", lg: "12" } },
                                [
                                  _c("div", { staticClass: "clearfix mb-4" }, [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary h4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              25,
                                              "F3 - Communication from production"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("GreenFilmDocs", {
                                    attrs: {
                                      GFId: _vm.GF.id,
                                      GFType: "greenfilm",
                                      GFSubType: "F3",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "order-2 d-none d-lg-block",
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c("div", { staticClass: "sidenav-sticky sticky-top pb-4" }, [
                    _vm.GF
                      ? _c("div", { staticClass: "card" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("nav", [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.GF.a1ElectricityFromLocalNetwork ||
                                _vm.GF.a2GreenEnergy ||
                                _vm.GF.a3LedLighting
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav1",
                                                modifiers: {
                                                  collapseNav1: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    26,
                                                    "Energy savings"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav1" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF
                                                  .a1ElectricityFromLocalNetwork
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-1",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-1', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    39,
                                                                    "A1 - Connection to local electricity network"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.a2GreenEnergy
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-2",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-2', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    4,
                                                                    "A2 - Green energy"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.a3LedLighting
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-3",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-3', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    5,
                                                                    "A3 - Led lighting"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.GF.b1VehiculeEuro5 ||
                                _vm.GF.b2VehiculeGreen ||
                                _vm.GF.b3AccomodationCloseBy ||
                                _vm.GF.b4GreenLabelAccomodation
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav2",
                                                modifiers: {
                                                  collapseNav2: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    27,
                                                    "Transport and accomodations"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav2" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF.b1VehiculeEuro5
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-4",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-4', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    6,
                                                                    "B1 - Vehicule Euro 5"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.b2VehiculeGreen
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-5",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-5', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    7,
                                                                    "B2 - Vehicule Green"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.b3AccomodationCloseBy
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-6",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-6', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    8,
                                                                    "B3 - Accomodation close by"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.b4GreenLabelAccomodation
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-7",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-7', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    9,
                                                                    "B4 - Green label accomodation"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.GF.c1TabWater ||
                                _vm.GF.c1WaterFontain ||
                                _vm.GF.c2Catering ||
                                _vm.GF.c2Restaurant ||
                                _vm.GF.c3ReusableDishes ||
                                _vm.GF.c4WashableCup ||
                                _vm.GF.c4RecyclableCup
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav3",
                                                modifiers: {
                                                  collapseNav3: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(28, "Catering")
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav3" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF.c1TabWater
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-8",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-8', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    10,
                                                                    "C1 - Tab water"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c1WaterFontain
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-9",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-9', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    11,
                                                                    "C1 - Water fontain"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c2Catering
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-10",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-10', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    12,
                                                                    "C2 - Catering"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c2Restaurant
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-11",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-11', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    13,
                                                                    "C2 - Restaurant"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c3ReusableDishes
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-12",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-12', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    14,
                                                                    "C3 - Reusable dishes"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c4WashableCup
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-13",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-13', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    15,
                                                                    "C4 - Washable cup"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.c4RecyclableCup
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-14",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-14', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    16,
                                                                    "C4 - Recyclable cup"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.GF.d1MaterialSupplierFsc ||
                                _vm.GF.d1MaterialSupplierEmas ||
                                _vm.GF.d2MaterialRecycled ||
                                _vm.GF.d3ReusableStageMaterial ||
                                _vm.GF.d4ElectronicInfoExchange
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav4",
                                                modifiers: {
                                                  collapseNav4: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(29, "Material")
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav4" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF.d1MaterialSupplierFsc
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-15",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-15', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    17,
                                                                    "D1 - Material supplier FSC"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.d1MaterialSupplierEmas
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-16",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-16', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    18,
                                                                    "D1 - Material supplier Emas"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.d2MaterialRecycled
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-17",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-17', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    19,
                                                                    "D2 - Material recycled"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.d3ReusableStageMaterial
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-18",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-18', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    20,
                                                                    "D3 - Reusable stage material"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.d4ElectronicInfoExchange
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-19",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-19', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    21,
                                                                    "D4 - Electronical info exchange"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.GF.e1SortWaste
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav5",
                                                modifiers: {
                                                  collapseNav5: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(30, "Waste")
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav5" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF.e1SortWaste
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-20",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-20', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    22,
                                                                    "E1 - Sort waste"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.GF.f1ComPressFile ||
                                _vm.GF.f2ComClip ||
                                _vm.GF.f3ComFromProduction
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.collapseNav6",
                                                modifiers: {
                                                  collapseNav6: true,
                                                },
                                              },
                                            ],
                                            staticClass: "collapse-btn",
                                            attrs: {
                                              variant: "link",
                                              block: "",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    31,
                                                    "Communication"
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c("i", {
                                              staticClass: "icon-arrow-down",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "b-collapse",
                                          { attrs: { id: "collapseNav6" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass: "nav flex-column",
                                              },
                                              [
                                                _vm.GF.f1ComPressFile
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-21",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-21', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    23,
                                                                    "F1 - Communication press file"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.f2ComClip
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-22",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-22', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    24,
                                                                    "F2 - Communication clip"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.GF.f3ComFromProduction
                                                  ? _c(
                                                      "li",
                                                      {
                                                        staticClass: "nav-item",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "scroll-to",
                                                                rawName:
                                                                  "v-scroll-to",
                                                                value: {
                                                                  el: "#form-step-23",
                                                                  offset: -88,
                                                                },
                                                                expression:
                                                                  "{ el: '#form-step-23', offset: -88 }",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: "#",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  this.FormMSG(
                                                                    25,
                                                                    "F3 - Communication from production"
                                                                  )
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c(
                          "b-col",
                          { attrs: { lg: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary", size: "lg" },
                                on: { click: _vm.downloadExport },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    this.FormMSG(
                                      201,
                                      "Download Green Film document"
                                    )
                                  ) + "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }