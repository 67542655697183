<style>
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.btn-link.collapse-btn {
	color: #323635;
	text-align: left;
	padding-left: 0;
	padding-right: 0;
	clear: both;
	transition: all 0.4s;
}

.btn-link.collapse-btn:hover,
.btn-link.collapse-btn:active,
.btn-link.collapse-btn:focus {
	padding-left: 8px;
	background-color: #f3f4f5;
	text-decoration: none;
}

.btn-link.collapse-btn i {
	float: right;
	margin: 6px 12px 0 0;
	font-size: 0.7rem;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<div>
			<!-- <b-row>
				<b-col cols="12" md="8">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">{{ this.FormMSG(1, 'Green Film Final Report') }}</h1>
				</b-col>
			</b-row> -->

			<b-row>
				<b-col colds="12" md="8">
					<b-card v-if="GF.a1ElectricityFromLocalNetwork">
						<b-row>
							<b-col id="form-step-1" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(39, 'A1 - Connection to local electricity network') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="A1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.a2GreenEnergy">
						<b-row>
							<b-col id="form-step-2" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(4, 'A2 - Green energy') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="A2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.a3LedLighting">
						<b-row>
							<b-col id="form-step-3" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(5, 'A3 - Led lighting') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="A3"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.b1VehiculeEuro5">
						<b-row>
							<b-col id="form-step-4" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(6, 'B1 - Vehicule Euro 5') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="B1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.b2VehiculeGreen">
						<b-row>
							<b-col id="form-step-5" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(7, 'B2 - Vehicule Green') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="B2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.b3AccomodationCloseBy">
						<b-row>
							<b-col id="form-step-6" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(8, 'B3 - Accomodation close by') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="B3"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.b4GreenLabelAccomodation">
						<b-row>
							<b-col id="form-step-7" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(9, 'B4 - Green label accomodation') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="B4"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c1TabWater">
						<b-row>
							<b-col id="form-step-8" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(10, 'C1 - Tab water') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C1-1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c1WaterFontain">
						<b-row>
							<b-col id="form-step-9" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(11, 'C1 - Water fontain') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C1-2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c2Catering">
						<b-row>
							<b-col id="form-step-10" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(12, 'C2 - Catering') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C2-1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c2Restaurant">
						<b-row>
							<b-col id="form-step-11" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(13, 'C2 - Restaurant') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C2-2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c3ReusableDishes">
						<b-row>
							<b-col id="form-step-12" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(14, 'C3 - Reusable dishes') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C3"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c4WashableCup">
						<b-row>
							<b-col id="form-step-13" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(15, 'C4 - Washable cup') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C4-1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.c4RecyclableCup">
						<b-row>
							<b-col id="form-step-14" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(16, 'C4 - Recyclable cup') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="C4-2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.d1MaterialSupplierFsc">
						<b-row>
							<b-col id="form-step-15" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(17, 'D1 - Material supplier FSC') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="D1-1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.d1MaterialSupplierEmas">
						<b-row>
							<b-col id="form-step-16" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(18, 'D1 - Material supplier Emas') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="D1-2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.d2MaterialRecycled">
						<b-row>
							<b-col id="form-step-17" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(19, 'D2 - Material recycled') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="D2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.d3ReusableStageMaterial">
						<b-row>
							<b-col id="form-step-18" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(20, 'D3 - Reusable stage material') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="D3"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.d4ElectronicInfoExchange">
						<b-row>
							<b-col id="form-step-19" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(21, 'D4 - Electronical info exchange') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="D4"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.e1SortWaste">
						<b-row>
							<b-col id="form-step-20" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(22, 'E1 - Sort waste') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="E1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.f1ComPressFile">
						<b-row>
							<b-col id="form-step-21" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(23, 'F1 - Communication press file') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="F1"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.f2ComClip">
						<b-row>
							<b-col id="form-step-22" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(24, 'F2 - Communication clip') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="F2"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF.f3ComFromProduction">
						<b-row>
							<b-col id="form-step-23" lg="12">
								<div class="clearfix mb-4">
									<h2 class="text-primary h4">{{ this.FormMSG(25, 'F3 - Communication from production') }}</h2>
								</div>
								<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="F3"></GreenFilmDocs>
							</b-col>
						</b-row>
					</b-card>
				</b-col>

				<b-col cols="12" md="4" class="order-2 d-none d-lg-block">
					<div class="sidenav-sticky sticky-top pb-4">
						<div class="card" v-if="GF">
							<div class="card-body">
								<nav>
									<ul class="nav flex-column">
										<li class="nav-item" v-if="GF.a1ElectricityFromLocalNetwork || GF.a2GreenEnergy || GF.a3LedLighting">
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav1>
												<strong>{{ this.FormMSG(26, 'Energy savings') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav1">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.a1ElectricityFromLocalNetwork">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-1', offset: -88 }" href="#">
															{{ this.FormMSG(39, 'A1 - Connection to local electricity network') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.a2GreenEnergy">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-2', offset: -88 }" href="#">
															{{ this.FormMSG(4, 'A2 - Green energy') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.a3LedLighting">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-3', offset: -88 }" href="#">
															{{ this.FormMSG(5, 'A3 - Led lighting') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
										<li
											class="nav-item"
											v-if="GF.b1VehiculeEuro5 || GF.b2VehiculeGreen || GF.b3AccomodationCloseBy || GF.b4GreenLabelAccomodation"
										>
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav2>
												<strong>{{ this.FormMSG(27, 'Transport and accomodations') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav2">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.b1VehiculeEuro5">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-4', offset: -88 }" href="#">
															{{ this.FormMSG(6, 'B1 - Vehicule Euro 5') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.b2VehiculeGreen">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-5', offset: -88 }" href="#">
															{{ this.FormMSG(7, 'B2 - Vehicule Green') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.b3AccomodationCloseBy">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-6', offset: -88 }" href="#">
															{{ this.FormMSG(8, 'B3 - Accomodation close by') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.b4GreenLabelAccomodation">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-7', offset: -88 }" href="#">
															{{ this.FormMSG(9, 'B4 - Green label accomodation') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
										<li
											class="nav-item"
											v-if="
												GF.c1TabWater ||
												GF.c1WaterFontain ||
												GF.c2Catering ||
												GF.c2Restaurant ||
												GF.c3ReusableDishes ||
												GF.c4WashableCup ||
												GF.c4RecyclableCup
											"
										>
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav3>
												<strong>{{ this.FormMSG(28, 'Catering') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav3">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.c1TabWater">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-8', offset: -88 }" href="#">
															{{ this.FormMSG(10, 'C1 - Tab water') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c1WaterFontain">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-9', offset: -88 }" href="#">
															{{ this.FormMSG(11, 'C1 - Water fontain') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c2Catering">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-10', offset: -88 }" href="#">
															{{ this.FormMSG(12, 'C2 - Catering') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c2Restaurant">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-11', offset: -88 }" href="#">
															{{ this.FormMSG(13, 'C2 - Restaurant') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c3ReusableDishes">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-12', offset: -88 }" href="#">
															{{ this.FormMSG(14, 'C3 - Reusable dishes') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c4WashableCup">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-13', offset: -88 }" href="#">
															{{ this.FormMSG(15, 'C4 - Washable cup') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.c4RecyclableCup">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-14', offset: -88 }" href="#">
															{{ this.FormMSG(16, 'C4 - Recyclable cup') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
										<li
											class="nav-item"
											v-if="
												GF.d1MaterialSupplierFsc ||
												GF.d1MaterialSupplierEmas ||
												GF.d2MaterialRecycled ||
												GF.d3ReusableStageMaterial ||
												GF.d4ElectronicInfoExchange
											"
										>
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav4>
												<strong>{{ this.FormMSG(29, 'Material') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav4">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.d1MaterialSupplierFsc">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-15', offset: -88 }" href="#">
															{{ this.FormMSG(17, 'D1 - Material supplier FSC') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.d1MaterialSupplierEmas">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-16', offset: -88 }" href="#">
															{{ this.FormMSG(18, 'D1 - Material supplier Emas') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.d2MaterialRecycled">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-17', offset: -88 }" href="#">
															{{ this.FormMSG(19, 'D2 - Material recycled') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.d3ReusableStageMaterial">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-18', offset: -88 }" href="#">
															{{ this.FormMSG(20, 'D3 - Reusable stage material') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.d4ElectronicInfoExchange">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-19', offset: -88 }" href="#">
															{{ this.FormMSG(21, 'D4 - Electronical info exchange') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
										<li class="nav-item" v-if="GF.e1SortWaste">
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav5>
												<strong>{{ this.FormMSG(30, 'Waste') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav5">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.e1SortWaste">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-20', offset: -88 }" href="#">
															{{ this.FormMSG(22, 'E1 - Sort waste') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
										<li class="nav-item" v-if="GF.f1ComPressFile || GF.f2ComClip || GF.f3ComFromProduction">
											<b-button variant="link" block class="collapse-btn" v-b-toggle.collapseNav6>
												<strong>{{ this.FormMSG(31, 'Communication') }}</strong>
												<i class="icon-arrow-down"></i>
											</b-button>
											<b-collapse id="collapseNav6">
												<ul class="nav flex-column">
													<li class="nav-item" v-if="GF.f1ComPressFile">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-21', offset: -88 }" href="#">
															{{ this.FormMSG(23, 'F1 - Communication press file') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.f2ComClip">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-22', offset: -88 }" href="#">
															{{ this.FormMSG(24, 'F2 - Communication clip') }}
														</a>
													</li>
													<li class="nav-item" v-if="GF.f3ComFromProduction">
														<a class="nav-link" v-scroll-to="{ el: '#form-step-23', offset: -88 }" href="#">
															{{ this.FormMSG(25, 'F3 - Communication from production') }}
														</a>
													</li>
												</ul>
											</b-collapse>
										</li>
									</ul>
								</nav>
							</div>
						</div>
						<div class="card-body">
							<b-col lg="6">
								<b-button variant="primary" size="lg" v-on:click="downloadExport"
									>{{ this.FormMSG(201, 'Download Green Film document') }}
								</b-button>
							</b-col>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';

import axios from 'axios';
import Loading from 'vue-loading-overlay';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import languageMessages from '@/mixins/languageMessages';
import { getGreenFilm,updGreenFilm } from '@/cruds/greenfilm.crud'

const query_GF = gql`
	query {
		GetGreenFilm(Label: 0) {
			id
			publisher {
				name
				firstName
			}
			type
			a1ElectricityFromLocalNetwork
			a1Value
			a2GreenEnergy
			a2Value
			a3LedLighting
			a3Value
			b1VehiculeEuro5
			b1Value
			b2VehiculeGreen
			b2Value
			b3AccomodationCloseBy
			b3Value
			b4GreenLabelAccomodation
			b4Value
			c1TabWater
			c1TabWaterValue
			c1WaterFontain
			c1TabWaterValue
			c2Catering
			c2CateringValue
			c2Restaurant
			c2RestaurantValue
			c3ReusableDishes
			c3Value
			c4WashableCup
			c4WashableCupValue
			c4RecyclableCup
			c4RecyclableCupValue
			d1MaterialSupplierFsc
			d1MaterialSupplierFscValue
			d1MaterialSupplierEmas
			d1MaterialSupplierEmasValue
			d2MaterialRecycled
			d2MaterialRecycledValue
			d3ReusableStageMaterial
			d3Value
			d4ElectronicInfoExchange
			d4Value
			e1SortWaste
			e1Value
			f1ComPressFile
			f1Value
			f2ComClip
			f2Value
			f3ComFromProduction
			f3Description
			f3Value
		}
	}
`;
const mutation_GFupdate = gql`
	mutation ($GreenFilmId: ID!, $UpdatedGreenFilm: GreenFilmInput!) {
		UpdGreenFilm(GreenFilmId: $GreenFilmId, UpdatedGreenFilm: $UpdatedGreenFilm) {
			id
			publisher {
				name
				firstName
			}
			type
			a1ElectricityFromLocalNetwork
			a1Value
			a2GreenEnergy
			a2Value
			a3LedLighting
			a3Value
			b1VehiculeEuro5
			b1Value
			b2VehiculeGreen
			b2Value
			b3AccomodationCloseBy
			b3Value
			b4GreenLabelAccomodation
			b4Value
			c1TabWater
			c1TabWaterValue
			c1WaterFontain
			c1TabWaterValue
			c2Catering
			c2CateringValue
			c2Restaurant
			c2RestaurantValue
			c3ReusableDishes
			c3Value
			c4WashableCup
			c4WashableCupValue
			c4RecyclableCup
			c4RecyclableCupValue
			d1MaterialSupplierFsc
			d1MaterialSupplierFscValue
			d1MaterialSupplierEmas
			d1MaterialSupplierEmasValue
			d2MaterialRecycled
			d2MaterialRecycledValue
			d3ReusableStageMaterial
			d3Value
			d4ElectronicInfoExchange
			d4Value
			e1SortWaste
			e1Value
			f1ComPressFile
			f1Value
			f2ComClip
			f2Value
			f3ComFromProduction
			f3Description
			f3Value
		}
	}
`;

export default {
	name: 'GreenFilmFinalReport',
	mixins: [languageMessages],

	apollo: {
		GF: function () {
			this.reloadData();
		}
	},

	components: {
		Loading,
		GreenFilmDocs
	},

	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			GF: {},
			warning: '',
			isLoading: false,
			totalPts: 0
		};
	},

	computed: {},

	methods: {
		computeTotalPoints() {
			this.totalPts = 0;
			if (this.GF.a1ElectricityFromLocalNetwork) {
				this.totalPts += this.GF.a1Value;
			}
			if (this.GF.b1VehiculeEuro5) {
				this.totalPts += this.GF.b1Value;
			}
			//        console.log("this.totalPts:",this.totalPts);
			return this.totalPts;
		},
		async reloadData() {
			this.isLoading = true;
			let greenFilm = await getGreenFilm()
			this.GF = await greenFilm;
			this.computeTotalPoints();
			this.isLoading = false;
		},
		async saveInformation() {
			//this.isLoading = true;
			var UpdatedGreenFilm = this.GF;
			var GreenFilmId = parseInt(this.GF.id, 10);
			//console.log("UpdatedGreenFilm:", UpdatedGreenFilm);
			this.GF = await this.updGreenFilm(GreenFilmId,UpdatedGreenFilm)
			this.computeTotalPoints();
			this.isLoading = false;
		},
		downloadExport() {
			console.log('indownload');

			this.isLoading = true;
			this.warning = '';

			var fromDate = this.from + 'T00:00:00Z';
			var toDate = this.to + 'T00:00:00Z';

			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};
			var servicePath = 'GreenFilmFinalReport';
			//console.log("servicePath:",servicePath);
			var fullPath = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=0';
			console.log('fullPath:', fullPath);

			var axiosTmp = require('axios').create();
			axiosTmp
				.get(fullPath, config)
				.then((response) => {
					if (response.status == 200) {
						var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;
						console.log('urlStr:', urlStr);
						this.$nextTick(function () {
							console.log('in nexttick url:', urlStr);
							//window.location.href = urlStr;
							window.open(urlStr); // in a new window
						});
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = this.FormMSG(22, 'No Data in the selected period.');
						}
					}
				})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
